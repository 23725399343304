<template>
  <div class="stories-view">
    <div class="stories-view__wrapper">
      <Section limiter>
        <BreadCrumbs :params="{navigation, color: 'rgba(0, 0, 0, .5)'}"/>
        <h1 class="stories-view__title">{{ $t('anchors.stories') }}</h1>
<!--        <Tabs>-->
<!--          <router-link to="/events"-->
<!--                       :class="$route.path === '/events' ? 'tabs__active' : ''"-->
<!--                       class="tabs__tab">События-->
<!--          </router-link>-->
<!--          <router-link to="/news"-->
<!--                       :class="$route.path === '/news' ? 'tabs__active' : ''"-->
<!--                       class="tabs__tab">Новости-->
<!--          </router-link>-->
<!--          <router-link to="/stories"-->
<!--                       :class="$route.path === '/stories' ? 'tabs__active' : ''"-->
<!--                       class="tabs__tab">Сюжеты-->
<!--          </router-link>-->
<!--        </Tabs>-->
        <Row class="-m-15"
             wrap>
          <Story class="stories-view__story"
                 v-for="story in $stories.data.filter(item => item.type !== 'panel')"
                 :key="story.id"
                 :params="story"/>
        </Row>
      </Section>
      <Section limiter>
        <Share :url="`${$host}/stories`"
               :title="share.title"
               :description="share.description"/>
      </Section>
<ModifiedBillboard/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StoriesView',
  created() {
    this.$store.dispatch('GET_STORIES_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      params: {
        resolution: 'medium',
        city_id: this.$city.id,
        count: 100500,
        lang: this.$i18n.locale,
      },
    });
  },
  computed: {
    navigation() {
      return [{
        href: '/',
        title: this.$i18n.t('menu.main'),
      }, {
        href: '/stories',
        title: this.$i18n.t('menu.playbill'),
      }];
    },
  },
};
</script>
